import React, { useState, useEffect } from 'react';
import { fetchWaitlistFromDB } from '../functions/waitlistFunctions';
import '../css/TeamWaitlist.css';

const TeamWaitlist = () => {
  const [selectedTournament, setSelectedTournament] = useState('');
  const [waitlist, setWaitlist] = useState([]);

  useEffect(() => {
    if (selectedTournament) {
      const fetchWaitlist = async () => {
        try {
          const data = await fetchWaitlistFromDB(selectedTournament);
          setWaitlist(data);
        } catch (error) {
          console.error('Error fetching waitlist:', error);
        }
      };
      fetchWaitlist();
    }
  }, [selectedTournament]);

  const handleTournamentSelect = (tournament) => {
    setSelectedTournament(prev => prev === tournament ? '' : tournament);
  };

  return (
    <div className="team-waitlist-container">
      <nav className="navbar2">
        <button onClick={() => handleTournamentSelect('U13-U15 (2012-2010)')}>U13-U15 (2012-2010)</button>
        <button onClick={() => handleTournamentSelect('U16+ (2009 & older)')}>U16+ (2009 & older)</button>
      </nav>

      {selectedTournament && (
        <div className="waitlist-list">
          <h2>{selectedTournament} Waitlist</h2>
          {waitlist.length > 0 ? (
            waitlist.map((player, index) => (
              <div key={index} className="player-card">
                <p>{player.firstName} {player.lastName}</p>
                <p>Date of Birth: {player.dob}</p>
                <p>Phone: {player.phone}</p>
              </div>
            ))
          ) : (
            <p>No players on the waitlist.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamWaitlist;
