import React, { useState, useEffect } from 'react';
import { fetchScheduleInDB, fetchStandingsFromDB, fetchTournamentIdByName } from '../functions/schedulesResultsFunctions';
import '../css/schedulesResults.css';

const SchedulesResults = () => {
  const [selectedTournament, setSelectedTournament] = useState('');
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isStandingsOpen, setIsStandingsOpen] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [standings, setStandings] = useState({});

  useEffect(() => {
    if (selectedTournament) {
      const fetchSchedule = async () => {
        try {
          const data = await fetchScheduleInDB(selectedTournament);
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(`${a.date}T${a.time}`);
            const dateB = new Date(`${b.date}T${b.time}`);
            return dateA - dateB;
          });
          setSchedule(sortedData);
        } catch (error) {
          console.error('Error fetching games:', error);
        }
      };
      fetchSchedule();
    }
  }, [selectedTournament]);

  useEffect(() => {
    if (selectedTournament) {
      const fetchStandings = async () => {
        try {
          const tournamentID = await fetchTournamentIdByName(selectedTournament);
          const brackets = selectedTournament === 'U16+ (2009 & older)' ? ['Bracket A', 'Bracket B'] : ['Bracket A'];
          console.log("BRACKETS:", brackets);
          const allStandings = {};

          for (const bracket of brackets) {
            const data = await fetchStandingsFromDB(tournamentID, bracket);
            const standingsWithStats = data.map(team => {
              const points = team.wins * 3 + team.ties;
              const goalDifference = team.goalsScored - team.goalsAgainst;
              return {
                ...team,
                points,
                goalDifference: goalDifference > 0 ? `+${goalDifference}` : `${goalDifference}`
              };
            });
            const sortedStandings = standingsWithStats.sort((a, b) => {
              if (b.points !== a.points) return b.points - a.points;
              if (b.goalDifference !== a.goalDifference) return parseInt(b.goalDifference) - parseInt(a.goalDifference);
              return b.goalsScored - a.goalsScored;
            });
            allStandings[bracket] = sortedStandings;
          }

          setStandings(allStandings);
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };
      fetchStandings();
    }
  }, [selectedTournament]);

  const handleTournamentSelect = (tournament) => {
    setSelectedTournament(prev => prev === tournament ? '' : tournament);
    setIsStandingsOpen(false);
    setIsScheduleOpen(false);
  };

  const toggleSchedule = () => {
    setIsScheduleOpen(prevState => !prevState);
    setIsStandingsOpen(false);
  };

  const toggleStandings = () => {
    setIsStandingsOpen(prevState => !prevState);
    setIsScheduleOpen(false);
  };

  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className='team-waitlist-container'>
      <nav className='navbar2'>
        <button onClick={() => handleTournamentSelect('U13-U15 (2012-2010)')}>U13-U15 (2012-2010)</button>
        <button onClick={() => handleTournamentSelect('U16+ (2009 & older)')}>U16+ (2009 & older)</button>
      </nav>

      {selectedTournament && (
        <nav className='navbar3'>
          <button onClick={() => toggleSchedule()}>Schedule</button>
          <button onClick={() => toggleStandings()}>Standings</button>
        </nav>
      )}

      {selectedTournament && isScheduleOpen && (
        <div className="schedule-list">
          <h2>{selectedTournament} Schedule</h2>
          {schedule && schedule.length > 0 ? (
            schedule.map((game, index) => (
              <div key={index} className="game-card">
                <p>Date: {new Date(game.date).toLocaleDateString()}</p>
                <p>Time: {formatTime(game.time)}</p>
                <div className="team-info">
                  <div className="home-team">
                    <p>Home Team: {game.team1Name}</p>
                    <p>Score: {game.team1Score}</p>
                  </div>
                  <div className="away-team">
                    <p>Away Team: {game.team2Name}</p>
                    <p>Score: {game.team2Score}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p></p>
          )}
        </div>
      )}

      {selectedTournament && isStandingsOpen && (
        <div className="standings-list">
          <h2>{selectedTournament} Standings</h2>
          {Object.keys(standings).map((bracket, index) => (
            <div key={index}>
              <h3>{bracket}</h3>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Team</th>
                    <th>MP</th>
                    <th>W</th>
                    <th>L</th>
                    <th>D</th>
                    <th>GF</th>
                    <th>GA</th>
                    <th>GD</th>
                    <th>PTS</th>
                  </tr>
                </thead>
                <tbody>
                  {standings[bracket].map((team, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}.</td>
                      <td>{team.name}</td>
                      <td>{team.gamesPlayed}</td>
                      <td>{team.wins}</td>
                      <td>{team.losses}</td>
                      <td>{team.ties}</td>
                      <td>{team.goalsScored}</td>
                      <td>{team.goalsAgainst}</td>
                      <td>{team.goalDifference}</td>
                      <td>{team.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SchedulesResults;
