import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../css/Contact.css'; // Ensure this path is correct

const Contact = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");

  const validateForm = () => {
    const errors = {};
    const formElements = form.current.elements;
    if (!formElements.from_name.value.trim()) {
      errors.from_name = 'Name is required';
    }
    if (!formElements.from_email.value.trim()) {
      errors.from_email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formElements.from_email.value.trim())) {
      errors.from_email = 'Email is invalid';
    }
    if (!formElements.contact_phone.value.trim()) {
      errors.contact_phone = 'Phone number is required';
    }
    if (!formElements.message.value.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, "").substring(0, 10);
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(formatPhoneNumber(e.target.value));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setIsLoading(true);

    emailjs
      .sendForm('service_lzpninj', 'template_hh9vpck', form.current, 'iG8KAz4yInBOt-kLu')
      .then(
        (result) => {
          setIsLoading(false);
          setIsSubmitted(true);
        },
        (error) => {
          setIsLoading(false);
          console.error('Failed to send email...', error.text);
        },
      )
      .catch((error) => {
        setIsLoading(false);
        console.error('Error occurred:', error);
      });
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      {isSubmitted ? (
        <div className="success-message">
          <h2>Thank you for your message!</h2>
          <p>We will get back to you as soon as possible.</p>
        </div>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="from_name" />
          {errors.from_name && <span className="error">{errors.from_name}</span>}
          
          <label>Email</label>
          <input type="email" name="from_email" />
          {errors.from_email && <span className="error">{errors.from_email}</span>}
          
          <label>Phone Number</label>
          <input type="text" name="contact_phone" value={phoneNumber} onChange={handlePhoneNumberChange} />
          {errors.contact_phone && <span className="error">{errors.contact_phone}</span>}
          
          <label>Message</label>
          <textarea name="message" />
          {errors.message && <span className="error">{errors.message}</span>}
          
          <input type="submit" value="Send" />
        </form>
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default Contact;
