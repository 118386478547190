import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { signUp, clearError } from "../store/auth";
import BasicButton from '../components/BasicButton';
import Input from '../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import { parse, differenceInYears } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/LogIn.css';
import supabase from '../lib/supabaseClient';
import { updateUserData } from '../functions/userFunctions';

const Register = () => {
    // State definitions
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [emergencyName, setEmergencyName] = useState('');
    const [emergencyNumber, setEmergencyNumber] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const error = useSelector((state) => state.authentication.error);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);

    // Effects for component lifecycle
    useEffect(() => {
        const handleAppStateChange = () => {
            if (document.visibilityState === 'visible') {
                supabase.auth.startAutoRefresh();
            } else {
                supabase.auth.stopAutoRefresh();
            }
        };
        document.addEventListener('visibilitychange', handleAppStateChange);
        return () => {
            document.removeEventListener('visibilitychange', handleAppStateChange);
            dispatch(clearError());
        };
    }, [dispatch]);

    // Helper functions
    const formatPhoneNumber = (value, setFunction) => {
        const phoneNumber = value.replace(/\D/g, '');
        if (phoneNumber.length <= 10) {
            setFunction(phoneNumber);
        }
    };

    const formatPhoneNumberForDisplay = (value) => {
        const phoneNumber = value.replace(/\D/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const validateFields = () => {
        const newErrors = {};
        if (!email) newErrors.email = "Email is required";
        if (!password) newErrors.password = "Password is required";
        if (!firstName) newErrors.firstName = "First Name is required";
        if (!lastName) newErrors.lastName = "Last Name is required";
        if (!phoneNumber || phoneNumber.length !== 10) newErrors.phoneNumber = "Phone Number must be 10 digits";
        if (!dateOfBirth) newErrors.dateOfBirth = "Date of Birth is required";
        if (calculateAge(dateOfBirth) < 18) {
            if (!emergencyName) newErrors.emergencyName = "Emergency Contact is required";
            if (!emergencyNumber || emergencyNumber.length !== 10) newErrors.emergencyNumber = "Emergency Number must be 10 digits";
        }
        if (!agreeToTerms) newErrors.agreeToTerms = "You must agree to the terms and conditions";
        return newErrors;
    };

    const handleDateInputChange = (e) => {
        const value = e.target.value;
        const date = parse(value, 'yyyy-MM-dd', new Date());
        if (!isNaN(date)) {
            setDateOfBirth(value);
        } else {
            setDateOfBirth('');
        }
    };

    const calculateAge = (dob) => {
        if (!dob) return 0;
        const parsedDate = parse(dob, 'yyyy-MM-dd', new Date());
        return differenceInYears(new Date(), parsedDate);
    };

    const signUpWithEmail = async () => {
        if (loading) return;

        const fieldErrors = validateFields();
        if (Object.keys(fieldErrors).length > 0) {
            setErrors(fieldErrors);
            return;
        }

        setLoading(true);
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
        const cleanedEmergencyNumber = emergencyNumber.replace(/\D/g, '');
        try {
            await dispatch(signUp({ email, password })).unwrap();
            setSuccess(true);
            const userData = {
                firstName,
                lastName,
                phone: cleanedPhoneNumber,
                dob: dateOfBirth,
            };
            if (calculateAge(dateOfBirth) < 18) {
                userData.emergencyName = emergencyName;
                userData.emergencyNumber = cleanedEmergencyNumber;
            }
            await updateUserData(email, userData);
        } catch (error) {
            console.error('Register error:', error);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => dispatch(clearError());

    return (
        <div className="container">
            <Modal message={error} onClose={closeModal} />
            {success && <Modal message={"Successfully registered"} onClose={() => {
                closeModal();
                navigate("/");
            }} />}
            <h1>Register</h1>
            <div>
                <Link to="/LogIn" className="swapScreens">Log In</Link>
            </div>
            <div className="input-container vertically-spaced mt20">
                <Input
                    label="Email"
                    leftIcon={{ name: 'fa-envelope' }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="email@address.com"
                    autoCapitalize="none"
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
            </div>
            <div className="input-container vertically-spaced">
                <Input
                    label="Password"
                    leftIcon={{ name: 'fa-lock' }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    autoCapitalize="none"
                    autoComplete="new-password"
                />
                {errors.password && <div className="error-message">{errors.password}</div>}
                <button 
                    type="button" 
                    className="secondary-button2" 
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? "Hide" : "Show"}
                </button>
            </div>
            <div className="input-container vertically-spaced">
                <Input
                    label="First Name"
                    leftIcon={{ name: 'fa-user' }}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder="First Name"
                    autoCapitalize="words"
                />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>
            <div className="input-container vertically-spaced">
                <Input
                    label="Last Name"
                    leftIcon={{ name: 'fa-user' }}
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    placeholder="Last Name"
                    autoCapitalize="words"
                />
                {errors.lastName && <div class="error-message">{errors.lastName}</div>}
            </div>
            <div className="input-container vertically-spaced">
                <Input
                    label="Phone Number"
                    leftIcon={{ name: 'fa-phone' }}
                    onChange={(e) => formatPhoneNumber(e.target.value, setPhoneNumber)}
                    value={formatPhoneNumberForDisplay(phoneNumber)}
                    placeholder="Phone Number"
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    maxLength={12}
                    inputMode="numeric"
                />
                {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
            </div>
            <div className="input-container vertically-spaced">
                <Input
                    label="Date of Birth"
                    leftIcon={{ name: 'fa-calendar' }}
                    onChange={handleDateInputChange}
                    value={dateOfBirth}
                    placeholder="YYYY-MM-DD"
                    type="date"
                />
                {errors.dateOfBirth && <div className="error-message">{errors.dateOfBirth}</div>}
            </div>
            {calculateAge(dateOfBirth) < 18 && (
                <>
                    <div className="input-container vertically-spaced">
                        <Input
                            label="Emergency Contact Full Name"
                            leftIcon={{ name: 'fa-user' }}
                            onChange={(e) => setEmergencyName(e.target.value)}
                            value={emergencyName}
                            placeholder="Emergency Name"
                            autoCapitalize="words"
                        />
                        {errors.emergencyName && <div className="error-message">{errors.emergencyName}</div>}
                    </div>
                    <div className="input-container vertically-spaced">
                        <Input
                            label="Emergency Phone Number"
                            leftIcon={{ name: 'fa-phone' }}
                            onChange={(e) => formatPhoneNumber(e.target.value, setEmergencyNumber)}
                            value={formatPhoneNumberForDisplay(emergencyNumber)}
                            placeholder="Emergency Number"
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            maxLength={12}
                            inputMode="numeric"
                        />
                        {errors.emergencyNumber && <div className="error-message">{errors.emergencyNumber}</div>}
                    </div>
                </>
            )}
            <div className="input-container vertically-spaced agreement-container">
                <input
                    type="checkbox"
                    className="checkbox-style"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                />
                <label>
                    I agree to the <Link to="/terms" className="agreement-link">terms and conditions</Link> of Araknid Cup
                </label>
                {calculateAge(dateOfBirth) < 18 && <p className="error-message">A parent or guardian must agree to the terms and conditions if the user is under 18.</p>}
                {errors.agreeToTerms && <div className="error-message">{errors.agreeToTerms}</div>}
            </div>
            <div className="fee-notice">The tournament fee is $175 per team, making it $25 per person. View payment methods at home page and footer. If full team payment is not recieved by noon on Friday the 26th your team will be ineligible to play.</div>
            <div className="button-container vertically-spaced mt20">
                <BasicButton title="Register" disabled={loading || !agreeToTerms} onClick={signUpWithEmail} />
            </div>
        </div>
    );
};

export default Register;
