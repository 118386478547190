import supabase from '../lib/supabaseClient';

export const fetchWaitlistFromDB = async (tournamentName) => {
  const { data: waitlist, error } = await supabase
    .from('Waitlist')
    .select('*')
    .eq('tournamentName', tournamentName);

  if (error) {
    console.error(error);
    throw new Error(error.message);
  }

  const userIds = waitlist.map(waitlistEntry => waitlistEntry.userID);

  const { data: users, error: userError } = await supabase
    .from('Users')
    .select('firstName, lastName, dob, phone')
    .in('id', userIds);

  if (userError) {
    console.error(userError);
    throw new Error(userError.message);
  }

  console.log("WAITLIST USERS:", users);
  return users;
};

export const joinWaitlist = async (name, userID) => {
  const { data, error } = await supabase
    .from('Waitlist')
    .insert([
      { tournamentName: name, userID }
    ])
    .select()
    .single();

  if (error) {
    console.error('Error joining waitlist:', error.message);
    throw new Error(error.message);
  } else {
    console.log("Waitlist joined successfully!");
  }

  return data;
};

export const removeFromWaitlist = async (userId) => {
  const { error: deleteError } = await supabase
    .from('Waitlist')
    .delete()
    .eq('userID', userId);

  if (deleteError) {
    console.error(deleteError);
    throw new Error(deleteError.message);
  } else {
    console.log("Player removed from waitlist successfully!")
  }

  return;
};

export const editWaitlist = async (newTournamentName, userId) => {
  const {data, error} = await supabase
    .from('Waitlist')
    .update({tournamentName: newTournamentName})
    .eq('userID',userId);

  if (error) {
    console.error('Error updating task:', error);
    return null;
  } else {
    console.log("Waitlist updated successfully!")
    console.log("EDITING DATA", data);
    return data;
  }
}

export const checkWaitlistStatus = async (userID) => {
  const { data, error } = await supabase
    .from('Waitlist')
    .select('userID')
    .eq('userID', userID);

  if (error) {
    console.error('Error checking waitlist status:', error.message);
    throw new Error(error.message);
  }

  return data.length > 0; // Returns true if the user is on any waitlist
};
