import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import supabase from '../lib/supabaseClient';
import '../css/Home.css';
import Video from '../media/araknid-movie.mp4';
import VideoPoster from '../media/video-poster.png';
import Image1 from '../media/four-training.jpeg';
import Image2 from '../media/Ian-back-turn.jpeg';
import Image3 from '../media/ian-trav-fernan.jpg';

const Home = () => {
  const isMobile = window.innerWidth <= 768;
  const session = useSelector((state) => state.authentication.session);
  const user = session ? session.user : null;
  const [firstName, setFirstName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserProfile = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('Users')
          .select('firstName')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching user profile:', error);
          return;
        }
        setFirstName(data.firstName);
      }
    };

    getUserProfile();
  }, [user]);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error);
    } else {
      dispatch({ type: 'LOGOUT' }); // Dispatch logout action
    }
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Welcome to Araknid Institute</h1>
        <div className="welcome-section">
          {user ? (
            <h2 className="welcome-text">Welcome, {firstName}!</h2>
          ) : (
            <h2 className="welcome-text">Join Us Today!</h2>
          )}
          <div className="button-group">
            {!user ? (
              <>
                <Link to="/register" className="home-button">Player Registration for Araknid Cup</Link>
                <Link to="/login" className="home-button">Log Into Araknid Cup Player Account</Link>
              </>
            ) : (
              <>
                <button onClick={handleLogout} className="home-button">Log Out</button>
                <Link to="/teamWaitlist" className="home-button">Tourney Waitlist</Link>
              </>
            )}
          </div>
        </div>
      </header>

      <nav className="nav-section3">
        <Link to="/teams" className="home-button">Teams</Link>
        <Link to="/schedules-results" className="home-button">Schedule & Results</Link>
      </nav>

      <div className="registration-message">
            <strong>ARAKNID CUP REGISTRATION CLOSES FRIDAY, JULY 26 AT NOON</strong>
          </div>


      <div className="registration-message">
      <strong>ARAKNID CUP $175 TEAM FEE DUE FRIDAY AT MIDNIGHT</strong>
      </div>


      <section className="video-section">
        <h2>Check out our latest video</h2>
        <video className="home-video" controls autoPlay={!isMobile} muted={!isMobile} playsInline poster={VideoPoster}>
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="cards-section">
        <Link to="/PaymentMethods" className="card-link">
          <div className="card">
            <h3>Araknid Cup Payment Methods</h3>
          </div>
        </Link>
        <Link to="/CupRules" className="card-link">
          <div className="card">
            <h3>Araknid Cup Tournament Rules</h3>
          </div>
        </Link>
        <Link to="/about" className="card-link">
          <div className="card">
            <h3>Meet the Araknid Institute Team</h3>
          </div>
        </Link>
        <Link to="/programs" className="card-link">
          <div className="card">
            <h3>Top Quality Training Programs</h3>
          </div>
        </Link>
      </section>

      <section className="info-section">
        <h2>About Araknid Institute</h2>
        <p>
          Araknid Institute is dedicated to advancing sports performance and strength conditioning. Our mission is to share knowledge gained through education and work experiences to help individuals reach their peak performance.
        </p>
      </section>

      <section className="gallery-section">
        <div className="gallery">
          <img src={Image1} alt="Training session" className="gallery-image" />
          <img src={Image2} alt="Ian back turned" className="gallery-image" />
          <img src={Image3} alt="Ian with Travis and Fernando" className="gallery-image" />
        </div>
      </section>
      <section className="instagram-section">
        <h2>Follow Us on Instagram</h2>
        <div className="instagram-embed">
          <iframe
            src="https://www.instagram.com/araknidinstitute/embed"
            width="320"
            height="450"
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
            title="Instagram Embed"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Home;
