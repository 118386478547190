import React from 'react';
import Venmo from '../media/Venmo.png';
import Cashapp from '../media/Cashapp.svg.png';
import Zelle from '../media/Zelle.png';
import '../css/PaymentMethods.css';

const PaymentMethods = () => {
  return (
    <div className="payment-methods-container">
      <h2>Payment Methods</h2>
      <div className="payment-method">
        <img src={Venmo} alt="Venmo" className="payment-logo" />
        <h3>Venmo</h3>
        <a href="https://account.venmo.com/u/ianjimenez2?catchAll=u&catchAll=ianjimenez2" target="_blank" rel="noopener noreferrer" className="payment-link">$ianjimenez2</a>
      </div>
      <div className="payment-method">
        <img src={Cashapp} alt="Cash App" className="payment-logo" />
        <h3>Cash App</h3>
        <a href="https://cash.app/ianjimenez2" target="_blank" rel="noopener noreferrer" className="payment-link">ianjimenez2</a>
      </div>
      <div className="payment-method">
        <img src={Zelle} alt="Zelle" className="payment-logo" />
        <h3>Zelle</h3>
        <p>Ian Jimenez, 954-817-9390</p>
      </div>
    </div>
  );
};

export default PaymentMethods;
