import React from 'react';
import '../css/Modal.css';

const Modal = ({ message, onClose, onConfirm }) => {
  if (!message) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <p>{message}</p>
          {onConfirm ? (
            <div className="modal-buttons">
              <button onClick={onConfirm}>Yes</button>
              <button onClick={onClose}>No</button>
            </div>
          ) : (
            <button onClick={onClose}>Close</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
