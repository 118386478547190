// import React from 'react'

// function merch() {
//   return (
//     <div>
//       <h1>Coming Soon!</h1>
//     </div>
//   )
// }

// export default merch
import '../css/merch.css'

import React from 'react'
function merch() {
  return (
    <div>
      <h1 className='coming-soon'>Coming soon!</h1>
    </div>
  )
}

export default merch
