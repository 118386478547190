import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';
import logo from '../araknid-logo.jpg'; // Adjust the path to your logo
import { FaBars, FaTimes } from 'react-icons/fa';

const NavBar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-left">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img src={logo} alt="Araknid Institute" className="navbar-logo-image" />
            </Link>
            <span className="navbar-title">Araknid Institute</span>
          </div>
          <div className="menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                <p>Home</p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/schedules-results' className='nav-links' onClick={closeMobileMenu}>
                <p>Schedules & Results</p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/CupRules' className='nav-links' onClick={closeMobileMenu}>
                <p>Tournament Rules & Information</p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/merch' className='nav-links' onClick={closeMobileMenu}>
                <p>Merch</p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/programs' className='nav-links' onClick={closeMobileMenu}>
                <p>Programs</p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                <p>About</p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                <p>Contact Us</p>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
