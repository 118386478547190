import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import supabase from '../lib/supabaseClient';

const initialState = {
    session: null,
    error: null,
    teamCode: null,
};

export const signIn = createAsyncThunk(
    "auth/signIn",
    async ({ email, password }, { rejectWithValue }) => {
        const { data: { session }, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        });
        if (error) {
            return rejectWithValue(error.message);
        }
        localStorage.setItem('supabaseSession', JSON.stringify(session));
        return session;
    }
);

export const signUp = createAsyncThunk(
    "auth/signUp",
    async ({ email, password }, { rejectWithValue }) => {
        const { data: { session }, error } = await supabase.auth.signUp({
            email,
            password,
        });
        if (error) {
            return rejectWithValue(error.message);
        }
        localStorage.setItem('supabaseSession', JSON.stringify(session));
        return session;
    }
);

export const signOut = createAsyncThunk(
    "auth/signOut",
    async (_, { rejectWithValue }) => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            return rejectWithValue(error.message);
        }
        localStorage.removeItem('supabaseSession');
        return null;
    }
);

const authSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setSession(state, action) {
            state.session = action.payload;
        },
        setTeamCode(state, action) {
            state.teamCode = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.fulfilled, (state, action) => {
                state.session = action.payload;
                state.error = null;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.session = action.payload;
                state.error = null;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.session = null;
                state.error = null;
            })
            .addCase(signOut.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export const { setSession, setTeamCode, clearError } = authSlice.actions;
export default authSlice.reducer;
