import supabase from '../lib/supabaseClient';

export const createGameInDB = async (time, date, team1Name, team2Name, tournamentName ) => {
    const { data, error } = await supabase
      .from('Games')
      .insert([
        { time, date, team1Name, team2Name, tournamentName }
      ])
      .select()
      .single();

    if (error) {
      console.error('Error creating game:', error.message);
      throw new Error(error.message);
    } else {
      console.log("Game created successfully!");
    }
    return data;
};

export const fetchScheduleInDB = async(tournamentName) => {
    const {data, error} = await supabase
        .from('Games')
        .select('*')
        .eq('tournamentName', tournamentName)
    if (error) {
        console.error(error);
        throw new Error(error.message);
    } else{
        console.log("FETCHED GAMES:", data);
        return data;
    }
};

export const updateGame = async (gameId, team1Score, team2Score) => {
  // Fetch the game to get team names
  const { data: game, error: fetchError } = await supabase
    .from('Games')
    .select('*')
    .eq('id', gameId)
    .single();

  if (fetchError) {
    console.error('Error fetching game:', fetchError);
    throw new Error(fetchError.message);
  }

  const { team1Name, team2Name } = game;

  // Update the game entry with the new scores
  const { data: updatedGame, error: updateError } = await supabase
    .from('Games')
    .update({ team1Score, team2Score })
    .eq('id', gameId)
    .single();

  if (updateError) {
    console.error('Error updating game scores:', updateError);
    throw new Error(updateError.message);
  }

  // Fetch the teams
  const { data: teams, error: fetchTeamsError } = await supabase
    .from('Teams')
    .select('*')
    .in('name', [team1Name, team2Name]);

  if (fetchTeamsError) {
    console.error('Error fetching teams:', fetchTeamsError);
    throw new Error(fetchTeamsError.message);
  }

  // Calculate new stats for both teams
  const updatedTeams = teams.map(team => {
    let goalsScored = team.goalsScored;
    let goalsAgainst = team.goalsAgainst;
    let wins = team.wins;
    let losses = team.losses;
    let ties = team.ties;
    let gamesPlayed = team.gamesPlayed;

    if (team.name === team1Name) {
      goalsScored += team1Score;
      goalsAgainst += team2Score;
      gamesPlayed += 1;
      if (team1Score > team2Score) {
        wins += 1;
      } else if (team1Score < team2Score) {
        losses += 1;
      } else {
        ties += 1;
      }
    } else if (team.name === team2Name) {
      goalsScored += team2Score;
      goalsAgainst += team1Score;
      gamesPlayed += 1;
      if (team2Score > team1Score) {
        wins += 1;
      } else if (team2Score < team1Score) {
        losses += 1;
      } else {
        ties += 1;
      }
    }

    const goalDifference = goalsScored - goalsAgainst;
    const points = wins * 3 + ties;

    return {
      ...team,
      goalsScored,
      goalsAgainst,
      gamesPlayed,
      wins,
      losses,
      ties,
      goalDifference,
      points,
    };
  });

  // Update the teams in the database
  const updates = updatedTeams.map(async team => {
    const { id, ...teamStats } = team;
    const { error: updateTeamError } = await supabase
      .from('Teams')
      .update(teamStats)
      .eq('id', id);

    if (updateTeamError) {
      console.error(`Error updating team ${team.name}:`, updateTeamError);
      throw new Error(updateTeamError.message);
    }
  });

  await Promise.all(updates);

  console.log("Teams updated successfully!");

  return updatedGame;
};


export const deleteGameInDB = async (gameId) => {
    const { error: deleteError } = await supabase
      .from('Games')
      .delete()
      .eq('id', gameId)

    if (deleteError) {
        console.error(deleteError);
        throw new Error(deleteError.message);
      } else {
        console.log("Game removed successfully!")
      }

      return;
};

export const fetchTournamentIdByName = async(tournamentName) => {
  const { data: tournamentIDs, error } = await supabase
    .from('Tournaments')
    .select('id')
    .eq('name', tournamentName);

  if (error) {
    console.error(error);
    throw new Error(error.message);
  } else {
    console.log("TOURNAMENT NAME FETCHED:", tournamentIDs);
    return tournamentIDs.length > 0 ? tournamentIDs[0].id : null;
  }
};

export const fetchStandingsFromDB = async (tournamentID, bracket) => {
  const { data: Teams, error } = await supabase
    .from('Teams')
    .select('*')
    .eq('tournamentID', tournamentID)
    .eq('bracket', bracket);

  if (error) {
    console.error(error);
    throw new Error(error.message);
  } else {
    return Teams;
  }
};

export const fetchPlayersForTeam = async (teamCode) => {
  const { data: players, error } = await supabase
    .from('Users')
    .select('*')
    .eq('teamCode', teamCode);

  if (error) {
    console.error(error);
    throw new Error(error.message);
  } else {
    console.log("PLAYERS FETCHED:", players);
    return players;
  }
};

export const updatePlayerGoals = async (playerId, goals) => {
  const { data: player, error: updateError } = await supabase
    .from('Users')
    .update({ goalScored: goals })
    .eq('id', playerId)
    .single();

  if (updateError) {
    console.error('Error updating player goals:', updateError);
    throw new Error(updateError.message);
  }

  console.log("Player goals updated successfully for player", player);
  return player;
};
