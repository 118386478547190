import supabase from "../lib/supabaseClient"

export const readUserId = async (email) => {
    let { data: Users, error } = await supabase
        .from('Users')
        .select('id')
        .eq("email", email);

    if (error) {
        console.error('Error reading user ID:', error);
        return null;
    }

    return Users[0];
}

export const updateUserData = async (email, userData) => {
    console.log('Updating user data:', userData);
    const { data, error } = await supabase
        .from('Users')
        .update(userData)
        .eq("email", email);

    if (error) {
        console.error('Error updating user data:', error);
        return null;
    }

    console.log('User data updated:', data);
    return data;
}
