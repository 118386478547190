import React from 'react';
import '../css/cupRules.css';

const CupRules = () => {
  return (
    <div className="cupRules-container">
      <h1>Araknid Cup Rules & Information</h1>
      <div className="cupRules-content">
        <div className="cupRules-section">
          <h2>Araknid Cup 7v7 Soccer Tournament</h2>
          <p>July 27-29</p>
          <p>$1,050 - $1,505 Cash Prize</p>
          <p>Registration opens within the next 24 hours</p>
        </div>
        <div className="cupRules-section">
          <h2>Who Can Play Where</h2>
          <h3>Age Groups</h3>
          <ul>
            <li>July 28: U13 - U15 (2012-2010)</li>
            <li>July 27: U16+ (2009 & older)</li>
          </ul>
          <p>Players may play up an age group</p>
          <p>Players cannot play down an age group</p>
          <p>Teams are co-ed, guys and girls may compete with and/or against each other</p>
        </div>
        <div className="cupRules-section">
          <h2>FAQs</h2>
          <ul>
            <li>Players may sign up individually and get picked up by a team with open spots</li>
            <li>Players only allowed to play on one team</li>
            <li>No subs</li>
            <li>GAMETIME & OVERTIME RULES:</li>
            <li>Group stage: 10 min game no OT if draw</li>
            <li>Semi/3rd place: 12 min game + PK shootout if draw</li>
            <li>Final: 15 min game + 3 min OT + PK shootout if draw</li>
          </ul>
        </div>
        <div className="cupRules-section">
          <h2>Top Scorer Prizes</h2>
          <h3>U16+</h3>
          <ul>
            <li>Free Highlight Tape</li>
            <li>Editing Service</li>
            <li>Free Jersey</li>
          </ul>
          <h3>U13-U15</h3>
          <ul>
            <li>Free Highlight Tape</li>
            <li>Editing Service</li>
            <li>Free Jersey</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CupRules;
