// src/pages/Programs.js
import React from 'react';
import '../css/programs.css';

const programs = [
  { name: 'ONE ON ONE S&C', price: '$40', duration: '60 MIN' },
  { name: 'ONE ON ONE SKILL', price: '$30', duration: '60 MIN' },
  { name: 'GROUP S&C', price: '$25/ATHLETE', duration: '60 MIN' },
  { name: 'GROUP SKILL', price: '$20/ATHLETE', duration: '60 MIN' },
  { name: 'BIRTHDAY PARTIES', price: '$10/PERSON', duration: '60 MIN' },
  { name: 'ONLINE PERSONAL TRAINING', plans: [
    { sessions: '4 days x 4 weeks = 16 sessions', details: '(4 1-on-1 zoom call, 12 solo)', price: '$232' },
    { sessions: '5 days x 4 weeks = 20 sessions', details: '(4 1-on-1 zoom call, 16 solo)', price: '$270' },
    { sessions: '6 days x 4 weeks = 24 sessions', details: '(4 1-on-1 zoom call, 20 solo)', price: '$315' },
  ]},
];

const Programs = () => {
  return (
    <div className="programs-container">
      <h1>Our Programs</h1>
      <div className="programs-grid">
        {programs.map((program, index) => (
          <div className="program-card" key={index}>
            <h2>{program.name}</h2>
            {program.plans ? (
              <div>
                {program.plans.map((plan, i) => (
                  <div className="program-plan" key={i}>
                    <p>{plan.sessions}</p>
                    <p>{plan.details}</p>
                    <p className="program-price">{plan.price}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <p className="program-price">{program.price}</p>
                <p className="program-duration">{program.duration}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;
