import React, { useEffect, useState } from 'react';
import '../css/LogIn.css';
import {
  createGameInDB,
  fetchScheduleInDB,
  updateGame,
  fetchTournamentIdByName,
  fetchPlayersForTeam,
  updatePlayerGoals
} from '../functions/schedulesResultsFunctions';
import { fetchTournamentsFromDB } from '../functions/teamFunctions';
import supabase from '../lib/supabaseClient';

const Admin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [isGamesOpen, setIsGamesOpen] = useState(false);
  const [isPlayersOpen, setIsPlayersOpen] = useState(false);
  const [isCreatingGame, setIsCreatingGame] = useState(false);
  const [isEditingGame, setIsEditingGame] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState('');
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [team1Name, setTeam1Name] = useState('');
  const [team2Name, setTeam2Name] = useState('');
  const [tournamentName, setTournamentName] = useState('');
  const [team1Score, setTeam1Score] = useState('');
  const [team2Score, setTeam2Score] = useState('');
  const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [gameScores, setGameScores] = useState({});
  const [roster, setRoster] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const tournaments = await fetchTournamentsFromDB();
        setTournaments(tournaments);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };

    fetchTournaments();
  }, []);

  useEffect(() => {
    if (tournamentName) {
      const fetchTeamsForTournament = async () => {
        try {
          const tournamentId = await fetchTournamentIdByName(tournamentName);
          const { data: teams, error } = await supabase
            .from('Teams')
            .select('*')
            .eq('tournamentID', tournamentId);

          if (error) {
            console.error('Error fetching teams:', error);
          } else {
            console.log("TEAMS:", teams);
            setTeams(teams);
          }
        } catch (error) {
          console.error('Error fetching tournament ID:', error);
        }
      };

      fetchTeamsForTournament();
    }
  }, [tournamentName]);

  useEffect(() => {
    if (selectedTournament) {
      const fetchSchedule = async () => {
        try {
          const data = await fetchScheduleInDB(selectedTournament);
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(`${a.date}T${a.time}`);
            const dateB = new Date(`${b.date}T${b.time}`);
            return dateA - dateB;
          });
          setSchedule(sortedData);
        } catch (error) {
          console.error('Error fetching games:', error);
        }
      };
      fetchSchedule();
    }
  }, [selectedTournament]);

  useEffect(() => {
    if (selectedTeam) {
      const fetchTeamRoster = async () => {
        try {
          const teamRoster = await fetchPlayersForTeam(selectedTeam.teamCode);
          setRoster(teamRoster);
        } catch (error) {
          console.error('Error fetching team roster:', error);
        }
      };

      fetchTeamRoster();
    }
  }, [selectedTeam]);

  const handleLogin = () => {
    if (username === 'ian' && password === 'ian') {
      setSuccess(true);
      setError('');
    } else {
      setError('Invalid credentials');
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess(false);
  };

  const toggleGames = () => {
    setIsGamesOpen((prevState) => !prevState);
    setIsPlayersOpen(false);
    setIsCreatingGame(false);
    setIsEditingGame(false);
  };

  const togglePlayers = () => {
    setIsPlayersOpen((prevState) => !prevState);
    setIsGamesOpen(false);
    setIsCreatingGame(false);
    setIsEditingGame(false);
  };

  const toggleCreateGame = () => {
    setIsCreatingGame((prevState) => !prevState);
    setIsEditingGame(false);
  };

  const toggleEditGame = () => {
    setIsEditingGame((prevState) => !prevState);
    setIsCreatingGame(false);
  };

  const toggleTournament = (tournament) => {
    setSelectedTournament((prev) => (prev === tournament ? '' : tournament));
  };

  const handleCreateGame = async () => {
    try {
      await createGameInDB(time, date, team1Name, team2Name, tournamentName);
      alert('Game created successfully!');
      setIsCreatingGame(false);
    } catch (error) {
      alert('Error creating game: ' + error.message);
    }
  };

  const handleScoreChange = (gameId, team, value) => {
    setGameScores(prev => ({
      ...prev,
      [gameId]: {
        ...prev[gameId],
        [team]: value
      }
    }));
  };
  
  const handleUpdateGame = async (gameId) => {
    const { team1Score, team2Score } = gameScores[gameId] || {};
    if (team1Score === undefined || team2Score === undefined) return;
  
    try {
      await updateGame(gameId, team1Score, team2Score);
      alert('Game updated successfully!');
      setIsEditingGame(false);
    } catch (error) {
      alert('Error updating game: ' + error.message);
    }
  };
  

  const handleAddGoal = async (playerId, currentGoals) => {
    try {
      await updatePlayerGoals(playerId, currentGoals + 1);
      const updatedRoster = roster.map(player =>
        player.id === playerId ? { ...player, goalScored: currentGoals + 1 } : player
      );
      setRoster(updatedRoster);
    } catch (error) {
      alert('Error updating goals: ' + error.message);
    }
  };

  const handleSubtractGoal = async (playerId, currentGoals) => {
    if (currentGoals <= 0) return; // Ensure goals don't go below 0
    try {
      await updatePlayerGoals(playerId, currentGoals - 1);
      const updatedRoster = roster.map(player =>
        player.id === playerId ? { ...player, goalScored: currentGoals - 1 } : player
      );
      setRoster(updatedRoster);
    } catch (error) {
      alert('Error updating goals: ' + error.message);
    }
  };

  // Generate date options for the next 30 days
  const dateOptions = Array.from({ length: 3 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i - 1); // Start from tomorrow
    return date.toISOString().split('T')[0];
  });

  // Generate time options every 5 minutes from 3:00 PM to 8:30 PM
  const generateTimeOptions = () => {
    const times = [];
    const start = new Date();
    start.setHours(15, 0, 0, 0); // 3:00 PM
    const end = new Date();
    end.setHours(20, 30, 0, 0); // 8:30 PM

    while (start <= end) {
      const hours = start.getHours().toString().padStart(2, '0');
      const minutes = start.getMinutes().toString().padStart(2, '0');
      times.push(`${hours}:${minutes}`);
      start.setMinutes(start.getMinutes() + 5);
    }

    return times;
  };

  const timeOptions = generateTimeOptions();

  return (
    <div className="container">
      {error && (
        <div className="message error-message">
          {error}
          <button onClick={clearMessages}>Close</button>
        </div>
      )}
      {success ? (
        <div>
          <nav className="navbar2">
            <button onClick={() => toggleGames()}>Games</button>
            <button onClick={() => togglePlayers()}>Players</button>
          </nav>
          {isGamesOpen && (
            <div className="navbar3">
              <button onClick={() => toggleCreateGame()}>Create a Game</button>
              <button onClick={() => toggleEditGame()}>Edit a Game</button>
            </div>
          )}
          {isCreatingGame && (
            <div className="create-team-form">
              <h3>Create a New Game</h3>
              <select
                className="team-select"
                value={tournamentName}
                onChange={(e) => setTournamentName(e.target.value)}
              >
                <option value="" disabled>
                  Select Tournament
                </option>
                {tournaments.map((tournament) => (
                  <option key={tournament.id} value={tournament.name}>
                    {tournament.name}
                  </option>
                ))}
              </select>
              <select
                className="team-select"
                value={team1Name}
                onChange={(e) => setTeam1Name(e.target.value)}
              >
                <option value="" disabled>
                  Select Team 1
                </option>
                {teams.map((team) => (
                  <option key={team.id} value={team.name}>
                    {team.name}
                  </option>
                ))}
              </select>
              <select
                className="team-select"
                value={team2Name}
                onChange={(e) => setTeam2Name(e.target.value)}
              >
                <option value="" disabled>
                  Select Team 2
                </option>
                {teams.map((team) => (
                  <option key={team.id} value={team.name}>
                    {team.name}
                  </option>
                ))}
              </select>
              <select
                className="team-select"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              >
                <option value="" disabled>
                  Select Date
                </option>
                {dateOptions.map((date) => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
              </select>
              <select
                className="team-select"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              >
                <option value="" disabled>
                  Select Time
                </option>
                {timeOptions.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
              <button className="submit-button" onClick={handleCreateGame}>
                Submit
              </button>
            </div>
          )}
        {isEditingGame && (
        <div>
            <div className="navbar3">
            <button onClick={() => toggleTournament('U13-U15 (2012-2010)')}>
                U13-U15 (2012-2010)
            </button>
            <button onClick={() => toggleTournament('U16+ (2009 & older)')}>
                U16+ (2009 & older)
            </button>
            </div>
            {selectedTournament && (
            <div className="create-team-form">
                <h3>Edit Game - {selectedTournament}</h3>
                {schedule.map((game) => (
                <div key={game.id} className="game-edit-section">
                    <p>
                    {game.team1Name} vs {game.team2Name} on {game.date} at {game.time}
                    </p>
                    <input
                    type="number"
                    placeholder="Team 1 Score"
                    value={gameScores[game.id]?.team1Score || ''}
                    onChange={(e) =>
                        handleScoreChange(game.id, 'team1Score', e.target.value)
                    }
                    />
                    <input
                    type="number"
                    placeholder="Team 2 Score"
                    value={gameScores[game.id]?.team2Score || ''}
                    onChange={(e) =>
                        handleScoreChange(game.id, 'team2Score', e.target.value)
                    }
                    />
                    <button
                    onClick={() => handleUpdateGame(game.id)}
                    >
                    Update Scores
                    </button>
                </div>
                ))}
            </div>
            )}
        </div>
        )}
          {isPlayersOpen && (
            <div>
              <div className="navbar3">
                <button onClick={() => setSelectedTournament('U13-U15 (2012-2010)')}>
                  U13-U15 (2012-2010)
                </button>
                <button onClick={() => setSelectedTournament('U16+ (2009 & older)')}>
                  U16+ (2009 & older)
                </button>
              </div>
              {selectedTournament && (
                <div className="team-list">
                  {teams.map((team) => (
                    <div
                      key={team.id}
                      className="team-card2"
                      onClick={() => setSelectedTeam(team)}
                    >
                      <h3>{team.name}</h3>
                    </div>
                  ))}
                </div>
              )}
              {selectedTeam && (
                <div className="team-roster">
                  <h3>Roster for {selectedTeam.name}</h3>
                  {roster.map((player) => (
                    <div key={player.id} className="player-card">
                      <p>{player.firstName} {player.lastName} - Goals: {player.goalScored}</p>
                      <button onClick={() => handleAddGoal(player.id, player.goalScored)}>+ Goal</button>
                      <button onClick={() => handleSubtractGoal(player.id, player.goalScored)}>- Goal</button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="admin-content">
          <h1>Admin Login</h1>
          <div className="input-container vertically-spaced mt20">
            <input
              className="team-input"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-container vertically-spaced">
            <div className="password-wrapper">
              <input
                className="team-input"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="secondary-button2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          <div className="button-container vertically-spaced mt20">
            <button className="secondary-button" onClick={handleLogin}>
              Log in
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
