import supabase from '../lib/supabaseClient';
import { removeFromWaitlist} from './waitlistFunctions'

export const createTeamInDB = async (teamName, tournamentID, userId) => {
  const teamCode = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a 6-digit random code
  console.log('TeamCode:', teamCode);
  const { data, error } = await supabase
    .from('Teams')
    .insert([
      { name: teamName, tournamentID, teamManager: userId, teamCode }
    ])
    .select()
    .single();

  if (error) {
    console.error('Error creating team:', error.message);
    throw new Error(error.message);
  } else {
    console.log("Team created successfully!");
  }

  const { error: updateError } = await supabase
    .from('Users')
    .update({ teamCode })
    .eq('id', userId);

  if (updateError) {
    console.error(updateError);
    throw new Error(updateError.message);
  } else {
    console.log("teamCode added to user");
  }

  await removeFromWaitlist(userId);

  console.log("NEW TEAM DATA:", data);
  return data;
};

export const joinTeamInDB = async (teamCode, userId) => {
  const { data: team, error } = await supabase
    .from('Teams')
    .select()
    .eq('teamCode', teamCode)
    .single();

  if (error) {
    console.error(error);
    throw new Error(error.message);
  }

  if (team.playerCount >= 7) {
    throw new Error('Team already has 7 players');
  }

  const { error: updateError } = await supabase
    .from('Users')
    .update({ teamCode })
    .eq('id', userId);

  if (updateError) {
    console.error(updateError);
    throw new Error(updateError.message);
  }

  const { error: playerCountError } = await supabase
    .from('Teams')
    .update({ playerCount: team.playerCount + 1 })
    .eq('teamCode', teamCode);

  if (playerCountError) {
    console.error(playerCountError);
    throw new Error(playerCountError.message);
  }

  await removeFromWaitlist(userId);

  console.log("team joined successfully", team);
  return team;
};

export const deleteTeamInDB = async (teamCode) => {
  const { error: updateError } = await supabase
    .from('Users')
    .update({ teamCode: null })
    .eq('teamCode', teamCode);

  if (updateError) {
    console.error(updateError);
    throw new Error(updateError.message);
  }

  const { error: deleteError } = await supabase
    .from('Teams')
    .delete()
    .eq('teamCode', teamCode);

  if (deleteError) {
    console.error(deleteError);
    throw new Error(deleteError.message);
  }

  return teamCode;
};

export const leaveTeamInDB = async (teamCode, userId) => {
  const { data: team, error } = await supabase
    .from('Teams')
    .select('*')
    .eq('teamCode', teamCode)
    .single();

  if (error) {
    console.error(error);
    throw new Error(error.message);
  }

  const { error: updateError } = await supabase
    .from('Users')
    .update({ teamCode: null })
    .eq('id', userId);

  if (updateError) {
    console.error(updateError);
    throw new Error(updateError.message);
  }

  const { error: playerCountError } = await supabase
    .from('Teams')
    .update({ playerCount: team.playerCount - 1 })
    .eq('teamCode', teamCode);

  if (playerCountError) {
    console.error(playerCountError);
    throw new Error(playerCountError.message);
  }

  return true;
};

export const fetchTournamentsFromDB = async () => {
  const { data: tournaments, error } = await supabase
    .from('Tournaments')
    .select('id, name');

  if (error) {
    console.error(error);
    throw new Error(error.message);
  }

  return tournaments;
};

export const fetchUserTournamentFromDB = async (tournamentID) => {
  const { data: tournament, error } = await supabase
    .from('Tournaments') // Ensure this matches the exact table name in your database
    .select('name')
    .eq('id', tournamentID)
    .single();

  if (error) {
    console.error(error);
    throw new Error(error.message);
  } else {
    console.log("Fetch tournamentName by tournamentID:", tournament);
  }

  return tournament;
};

export const fetchTeamByCode = async (teamCode) => {
  const { data: team, error } = await supabase
    .from('Teams')
    .select()
    .eq('teamCode', teamCode)
    .single();

  if (error) {
    console.error(error);
    throw new Error(error.message);
  } else {
    console.log("Fetch team by code:", team);
  }

  return team;
};

export const fetchTeamById = async (teamId) => {
  const { data: team, error } = await supabase
    .from('Teams')
    .select()
    .eq('id', teamId)
    .single();

  if (error) {
    console.error(error);
    throw new Error(error.message);
  } else {
    console.log("Fetch team by ID:", team);
  }

  return team;
};

export const fetchPlayersForTeam = async (teamCode) => {
  const { data: players, error } = await supabase
    .from('Users')
    .select('id, firstName, lastName, goalScored')
    .eq('teamCode', teamCode);

  if (error) {
    console.error(error);
    throw new Error(error.message);
  }

  console.log('PLAYERS;', players)
  return players;
};

export const subscribeToTeams = (tournamentID, callback) => {
  return supabase
    .channel('public:Teams')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'Teams', filter: `tournamentID=eq.${tournamentID}` }, payload => {
      callback(payload);
    })
    .subscribe();
};

export const subscribeToUsers = (callback) => {
  return supabase
    .channel('public:Users')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'Users' }, payload => {
      callback(payload);
    })
    .subscribe();
};
