import React from 'react';
import '../css/Terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h2>Terms and Conditions</h2>
      <p>
        By participating in the Araknid Institute LLC Soccer Tournament, you acknowledge that photographs and videos will be taken during the event. You grant Araknid Institute LLC the right to use, publish, and distribute any photographs, videos, or other media taken during the event for marketing, advertising, and promotional purposes.
      </p>
      <h3>Liability Release</h3>
      <p>
        Participation in the Araknid Institute LLC Soccer Tournament involves inherent risks, including but not limited to physical injury. By participating in this event, you release and hold harmless Araknid Institute LLC, its directors, officers, employees, volunteers, and all associated persons from any and all liability, claims, or demands for personal injury, illness, or property damage arising from participation in this event.
      </p>
      <h3>Team and Age Verification</h3>
      <p>
        By registering or participating in the tournament, you confirm that the information provided regarding your age and team registration is accurate and truthful. Any false information may result in disqualification from the tournament and forfeiture of any fees paid. Once registered or joined to a team, changes cannot be made and no refunds will be issued.
      </p>
      <h3>Emergency Contact Information</h3>
      <p>
        You are required to provide accurate emergency contact information, including the name, relationship, phone number, and email of an emergency contact. This information is necessary for your safety and the safety of other participants.
      </p>
      <h3>Acknowledgment</h3>
      <p>
        By agreeing to these terms and conditions, you confirm that you have read and fully understand the terms set forth. You agree to abide by these terms and conditions during your participation in the Araknid Institute LLC Soccer Tournament.
      </p>
    </div>
  );
};

export default Terms;
