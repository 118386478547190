import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { signIn, clearError } from "../store/auth";
import supabase from '../lib/supabaseClient';
import BasicButton from '../components/BasicButton';
import Input from '../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import '../css/LogIn.css';

const LogIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const error = useSelector((state) => state.authentication.error);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const handleAppStateChange = () => {
            if (document.visibilityState === 'visible') {
                supabase.auth.startAutoRefresh();
            } else {
                supabase.auth.stopAutoRefresh();
            }
        };

        document.addEventListener('visibilitychange', handleAppStateChange);

        return () => {
            document.removeEventListener('visibilitychange', handleAppStateChange);
        };
    }, []);

    async function signInWithEmail() {
        setLoading(true);
        dispatch(signIn({ email, password }))
            .unwrap()
            .then(() => {
                setSuccess(true);
            })
            .catch((error) => {
                console.error('Login error:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const closeModal = () => dispatch(clearError());

    return (
        <div className="container">
            <Modal message={error} onClose={closeModal} />
            {success && <Modal message={"Successfully logged in"} onClose={() => {
                closeModal();
                navigate("/");
            }} />}
            <h1>Log In</h1>
            <div>
                <Link to="/register" className="swapScreens">Register</Link>
            </div>
            <div className="input-container vertically-spaced mt20">
                <Input
                    label="Email"
                    leftIcon={{ name: 'fa-envelope' }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="email@address.com"
                    autoCapitalize="none"
                />
            </div>
            <div className="input-container vertically-spaced">
                <div className="password-wrapper">
                    <Input
                        label="Password"
                        leftIcon={{ name: 'fa-lock' }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        autoCapitalize="none"
                    />
                    <button 
                        type="button" 
                        className="secondary-button2" 
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? "Hide" : "Show"}
                    </button>
                </div>
            </div>
            <div className="button-container vertically-spaced mt20">
                <BasicButton title="Log in" disabled={loading} onClick={signInWithEmail} />
            </div>
        </div>
    );
};

export default LogIn;
