import { createClient } from '@supabase/supabase-js';
import localforage from 'localforage';


const supabaseUrl = "https://bbnimunvxzcmsdcmgphc.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJibmltdW52eHpjbXNkY21ncGhjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA4MTg5NzQsImV4cCI6MjAzNjM5NDk3NH0.a5stzCUN4x8x1eeXTkEWLH1b2tvVbjBGeXmiUueVcMA"
const supabase = createClient(supabaseUrl, supabaseKey, {
    auth: {
        storage: localforage,
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: true, 
      },
  })

export default supabase;
