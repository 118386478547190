import React from 'react';

const Input = ({ label, leftIcon, onChange, value, type = 'text', placeholder, autoCapitalize = 'none' }) => {
    return (
        <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>
                {label}
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {leftIcon && (
                    <i className={`fa ${leftIcon.name}`} style={{ marginRight: '10px' }} />
                )}
                <input 
                    type={type} 
                    onChange={onChange} 
                    value={value} 
                    placeholder={placeholder} 
                    style={{
                        padding: '10px',
                        width: '100%',
                        borderRadius: '5px',
                        border: '1px solid #ccc'
                    }}
                    autoCapitalize={autoCapitalize}
                />
            </div>
        </div>
    );
};

export default Input;
