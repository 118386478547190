import React, { useEffect } from 'react';
import './App.css';
import NavBar from './components/NavBar.js';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import About from './pages/about';
import Admin from './pages/admin';
import Home from './pages/Home';
import Merch from './pages/merch';
import SchedulesResults from './pages/schedulesResults';
import CupRules from './pages/cupRules';
import Programs from './pages/programs';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';
import LogIn from './pages/LogIn.js';
import Register from './pages/Register.js';
import Terms from './pages/terms'
import Teams from './pages/Teams'
import TeamWaitlist from './pages/TeamWaitlist'
import PaymentMethods from './pages/PaymentMethods'
import { useDispatch } from 'react-redux';
import { setSession } from '../src/store/auth';
import supabase from './lib/supabaseClient';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('supabaseSession'));
    if (session) {
      console.log('Restoring session:', session);
      dispatch(setSession(session));
      supabase.auth.setSession(session);
    }

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        console.log('Session changed:', session);
        localStorage.setItem('supabaseSession', JSON.stringify(session));
        dispatch(setSession(session));
      } else {
        console.log('Session cleared');
        localStorage.removeItem('supabaseSession');
        dispatch(setSession(null));
      }
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/schedules-results" element={<SchedulesResults />} />
        <Route path="/cupRules" element={<CupRules />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/teamWaitlist" element={<TeamWaitlist />} />
        <Route path="/PaymentMethods" element={<PaymentMethods />} />
        <Route path="/admin" element={<Admin />} />
        {/* Redirect to Home if no other routes match */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
