import React from 'react';
import '../css/Footer.css'; // Ensure this path is correct
import { FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <a href="https://www.linkedin.com/company/araknid/" target="_blank" rel="noopener noreferrer" className="footer-link">
            <FaLinkedin />
          </a>
        </div>
        <div className="footer-contact">
          <p>Email: <a href="mailto:araknidhealth@gmail.com">araknidhealth@gmail.com</a></p>
          <p>Phone: <a href="tel:+19548179390">954-817-9390</a></p>
          <p><Link to="/PaymentMethods" className="footer-link">Araknid Cup Payment Methods</Link></p> {/* Changed the path */}
          <p><Link to="/terms" className="footer-link">Terms and Conditions</Link></p> {/* Changed the path */}
          <p><Link to="/admin" className="footer-link">Admin</Link></p> {/* Changed the path */}

        </div>
      </div>
    </footer>
  );
};

export default Footer;
