import React from 'react';
import '../css/about.css'; // Import CSS for styling
import ceoImage from '../ianHeadshot.jpg'; // Import the CEO image

function About() {
  return (
    <div className="about-container">
      <h1>Meet the Araknid Institute Team</h1>
      <p>
        Araknid Institute is dedicated to advancing sports performance and strength conditioning. Our mission is to share knowledge gained through education and work experiences to help individuals reach their peak performance.
        Ian Jimenez, founder and CEO, is passionate about sports performance and strength conditioning. With a Bachelor's degree in Kinesiology, Ian plans to further his education in Exercise Physiology and Human Performance. His experience includes coaching youth soccer, interning in pro and collegiate sports science, and working as a Physical Therapy Technician.
      </p>
      <div className="team-cards">
        <div className="card">
          <img src={ceoImage} alt="CEO" className="card-image" />
          <div className="card-content">
            <h3>Ian Jimenez</h3>
            <p>Founder and CEO</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
