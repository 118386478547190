import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../lib/supabaseClient';
import { useSelector } from 'react-redux';
import {
  fetchTeamByCode,
  fetchUserTournamentFromDB,
  fetchPlayersForTeam,
  createTeamInDB,
  joinTeamInDB,
  deleteTeamInDB,
  leaveTeamInDB,
  fetchTournamentsFromDB,
  subscribeToTeams,
  subscribeToUsers,
} from '../functions/teamFunctions';
import { joinWaitlist, removeFromWaitlist, checkWaitlistStatus, editWaitlist } from '../functions/waitlistFunctions';
import Modal from '../components/Modal';
import '../css/Teams.css';

const Teams = () => {
  const [newTeamName, setNewTeamName] = useState('');
  const [teamCode, setTeamCode] = useState('');
  const [selectedTournamentCreate, setSelectedTournamentCreate] = useState('');
  const [selectedTournamentWaitlist, setSelectedTournamentWaitlist] = useState('');
  const [editedTournamentWaitlist, setEditedTournamentWaitlist] = useState('');
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const [isJoiningTeam, setIsJoiningTeam] = useState(false);
  const [isJoiningWaitlist, setIsJoiningWaitlist] = useState(false);
  const [isEditingWaitlist, setIsEditingWaitlist] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [confirmLeaveTeamModal, setConfirmLeaveTeamModal] = useState(false);
  const [confirmLeaveWaitlistModal, setConfirmLeaveWaitlistModal] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teams, setTeams] = useState([]);
  const [userTournament, setUserTournament] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [players, setPlayers] = useState({});
  const [isTeamManager, setIsTeamManager] = useState(false);
  const [isOnWaitlist, setIsOnWaitlist] = useState(false);

  const session = useSelector((state) => state.authentication.session);
  const user = session ? session.user : null;

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const tournaments = await fetchTournamentsFromDB();
        setTournaments(tournaments);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };
    fetchTournaments();
  }, []);

  useEffect(() => {
    const getUserProfile = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('Users')
          .select('firstName, lastName, dob, teamCode')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching user profile:', error);
          return;
        }
        if (data.teamCode) {
          setTeamCode(data.teamCode);
          const team = await fetchTeamByCode(data.teamCode);
          if (team) {
            setSelectedTeam(team);
            const teamPlayers = await fetchPlayersForTeam(team.teamCode);
            setPlayers({ [team.teamCode]: teamPlayers });
            const tournamentName = await fetchUserTournamentFromDB(team.tournamentID);
            setUserTournament(tournamentName);
            if (team.teamManager === user.id) {
              setIsTeamManager(true);
            }
          }
        }
        const waitlistStatus = await checkWaitlistStatus(user.id);
        setIsOnWaitlist(waitlistStatus);
      }
    };

    getUserProfile();
  }, [user]);

  useEffect(() => {
    let teamsSubscription;
    let usersSubscription;

    if (selectedTeam) {
      teamsSubscription = subscribeToTeams(selectedTeam.tournamentId, payload => {
        setTeams(prevTeams => {
          const updatedTeams = [...prevTeams];
          if (payload.eventType === 'DELETE') {
            return updatedTeams.filter(team => team.teamCode !== payload.old.teamCode);
          }
          const index = updatedTeams.findIndex(team => team.id === payload.new.id);
          if (index !== -1) {
            updatedTeams[index] = payload.new;
          } else {
            updatedTeams.push(payload.new);
          }
          return updatedTeams;
        });
      });
      usersSubscription = subscribeToUsers(async (payload) => {
        const updatedTeamCode = payload.new.teamCode;
        const updatedPlayer = { id: payload.new.id, firstName: payload.new.firstName, lastName: payload.new.lastName };

        setPlayers(prevPlayers => {
          const newPlayers = { ...prevPlayers };

          if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') {
            if (!newPlayers[updatedTeamCode]) {
              newPlayers[updatedTeamCode] = [];
            }
            const playerIndex = newPlayers[updatedTeamCode].findIndex(player => player.id === updatedPlayer.id);
            if (playerIndex === -1) {
              newPlayers[updatedTeamCode].push(updatedPlayer);
            } else {
              newPlayers[updatedTeamCode][playerIndex] = updatedPlayer;
            }
          }

          if (payload.eventType === 'DELETE') {
            Object.keys(newPlayers).forEach(teamCode => {
              newPlayers[teamCode] = newPlayers[teamCode].filter(player => player.id !== updatedPlayer.id);
            });
          }

          return newPlayers;
        });
      });
    }

    return () => {
      if (teamsSubscription) {
        supabase.removeChannel(teamsSubscription);
      }
      if (usersSubscription) {
        supabase.removeChannel(usersSubscription);
      }
    };
  }, [selectedTeam]);

  const handleCreateTeam = async () => {
    if (!newTeamName || !selectedTournamentCreate) {
      setModalMessage('Please enter a team name and select a tournament.');
      setShowModal(true);
      return;
    }
    try {
      const team = await createTeamInDB(newTeamName, selectedTournamentCreate, user.id);
      setModalMessage('Team created successfully!');
      setShowModal(true);
      setIsCreatingTeam(false);
      setTeams(prevTeams => [...prevTeams, team]);
      setTeamCode(team.teamCode); // Set the team code for the new team
      setSelectedTeam(team); // Set the selected team to the newly created team
      setIsTeamManager(true); // Set the user as the team manager

      // Fetch team players and tournament name immediately after creating the team
      const teamPlayers = await fetchPlayersForTeam(team.teamCode);
      setPlayers({ [team.teamCode]: teamPlayers });
      const tournamentName = await fetchUserTournamentFromDB(team.tournamentID);
      setUserTournament(tournamentName);
    } catch (error) {
      console.error(error);
      setModalMessage('Failed to create team. Please try again.');
      setShowModal(true);
    }
  };

  const handleJoinTeam = async () => {
    if (!teamCode) {
      setModalMessage('Please enter a team code.');
      setShowModal(true);
      return;
    }
    try {
      const team = await joinTeamInDB(teamCode, user.id);
      setModalMessage('Joined team successfully!');
      setShowModal(true);
      setIsJoiningTeam(false);
      setSelectedTeam(team);

      // Fetch team players and tournament name immediately after joining the team
      const teamPlayers = await fetchPlayersForTeam(team.teamCode);
      setPlayers({ [team.teamCode]: teamPlayers });
      const tournamentName = await fetchUserTournamentFromDB(team.tournamentID);
      setUserTournament(tournamentName);
    } catch (error) {
      setModalMessage('Failed to join team. Please try again.');
      setShowModal(true);
    }
  };

  const handleJoinWaitlist = async () => {
    if (!selectedTournamentWaitlist) {
      setModalMessage('Please select a tournament to join the waitlist.');
      setShowModal(true);
      return;
    }
    try {
      await joinWaitlist(selectedTournamentWaitlist, user.id);
      setModalMessage('Joined waitlist successfully!');
      setShowModal(true);
      setIsJoiningWaitlist(false);
      setIsOnWaitlist(true); // Update the state to reflect the waitlist status
    } catch (error) {
      setModalMessage('Failed to join waitlist. Please try again.');
      setShowModal(true);
    }
  };

  const handleEditWaitlist = async () => {
    if (!editedTournamentWaitlist) {
      setModalMessage('Please select a tournament to edit the waitlist.');
      setShowModal(true);
      return;
    }
    try {
      await editWaitlist(editedTournamentWaitlist, user.id);
      setModalMessage('Edited waitlist successfully!');
      setShowModal(true);
      setIsEditingWaitlist(false);
    } catch (error) {
      setModalMessage('Failed to edit waitlist. Please try again.');
      setShowModal(true);
    }
  };

  const handleConfirmLeaveWaitlist = async () => {
    try {
      await removeFromWaitlist(user.id);
      setModalMessage('Left waitlist successfully!');
      setShowModal(true);
      setIsJoiningWaitlist(false);
      setIsOnWaitlist(false); // Update the state to reflect the waitlist status
      setConfirmLeaveWaitlistModal(false);
    } catch (error) {
      setModalMessage('Failed to leave waitlist. Please try again.');
      setShowModal(true);
    }
  };

  const handleLeaveTeam = async () => {
    setConfirmLeaveTeamModal(true);
  };

  const handleConfirmLeaveTeam = async () => {
    try {
      await leaveTeamInDB(teamCode, user.id);
      setModalMessage('Left team successfully!');
      setShowModal(true);
      setSelectedTeam(null); // Clear selected team on leave
      setIsTeamManager(false); // Clear team manager status on leave
      setTeamCode(''); // Clear the team code on leave
      setConfirmLeaveTeamModal(false);
    } catch (error) {
      setModalMessage('Failed to leave team. Please try again.');
      setShowModal(true);
    }
  };

  const handleDeleteTeam = async () => {
    if (!teamToDelete) return;

    try {
      await deleteTeamInDB(teamToDelete);
      setModalMessage('Team deleted successfully!');
      setShowModal(true);
      setConfirmDeleteModal(false);
      setSelectedTeam(null); // Clear selected team on delete
      setIsTeamManager(false); // Clear team manager status on delete
      setTeamCode(''); // Clear the team code on delete
    } catch (error) {
      setModalMessage('Failed to delete team. Please try again.');
      setShowModal(true);
    }
  };

  /**
   * TOGGLE STANDINGS()
   * TOGGLE NAVABAR3 SCHEDULE AND STANDINGS VIEW
   * CREATE STANDINGS TABLE CARDS WITH CHAT GPT
   */
  const confirmDelete = (teamCode) => {
    console.log(teams);
    setTeamToDelete(teamCode);
    setConfirmDeleteModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage('');
  };

  const toggleCreateTeamForm = () => {
    setIsCreatingTeam(prevState => !prevState);
    setIsJoiningTeam(false);
    setIsJoiningWaitlist(false);
    setIsEditingWaitlist(false);
  };

  const toggleJoinTeamForm = () => {
    setIsJoiningTeam(prevState => !prevState);
    setIsCreatingTeam(false);
    setIsJoiningWaitlist(false);
    setIsEditingWaitlist(false);
  };

  const toggleJoinWaitlistForm = () => {
    setIsJoiningWaitlist(prevState => !prevState);
    setIsCreatingTeam(false);
    setIsJoiningTeam(false);
    setIsEditingWaitlist(false);
  };

  const toggleEditWaitlistForm = () => {
    setIsEditingWaitlist(prevState => !prevState);
    setIsJoiningWaitlist(false);
    setIsCreatingTeam(false);
    setIsJoiningTeam(false);
  };

  if (!user) {
    return (
      <div className="teams-container">
        <p>Log in or register to join, create, or view your team</p>
        <div className="auth-links">
          <Link to="/register" className="home-button">Register</Link>
          <Link to="/login" className="home-button">Log In</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="teams-container">
      {user && selectedTeam && (
        <div className="user-team-info">
          <h2>My Team: {selectedTeam.name}</h2>
          <p>Team Code: {selectedTeam.teamCode}</p>
          <p>Tournament: {userTournament.name}</p>
          <h3>Roster</h3>
          <div className="player-list">
            {(players[selectedTeam.teamCode] || []).map((player, index) => (
              <div key={player?.id || index} className="player-card">
                {player
                ? `${player.firstName} ${player.lastName} - Goals Scored: ${player.goalScored}`
                : 'Empty Slot'}
              </div>
            ))}
          </div>
          {isTeamManager && (
            <button className="action-button" onClick={() => confirmDelete(selectedTeam.teamCode)}>Delete Team</button>
          )}
          {!isTeamManager && (
            <button className="action-button" onClick={handleLeaveTeam}>Leave Team</button>
          )}
        </div>
      )}

{user && !teamCode && (
  <>
    <div className="team-actions">
      <button className="action-button" onClick={toggleCreateTeamForm}>Create a Team</button>
      <button className="action-button" onClick={toggleJoinTeamForm}>Join a Team</button>
      {isOnWaitlist ? (
        <>
          <button className="action-button" onClick={() => setConfirmLeaveWaitlistModal(true)}>Leave Tournament Waitlist</button>
          <button className="action-button" onClick={toggleEditWaitlistForm}>Edit Tournament Waitlist</button>
        </>
      ) : (
        <button className="action-button" onClick={toggleJoinWaitlistForm}>Join Tournament Waitlist</button>
      )}
    </div>
  </>
)}


      {isCreatingTeam && (
        <div className="create-team-form">
          <h3>Create a New Team</h3>
          <select
            className="team-select"
            value={selectedTournamentCreate}
            onChange={(e) => setSelectedTournamentCreate(e.target.value)}
          >
            <option value="" disabled>
              Select Tournament
            </option>
            {tournaments.map((tournament) => (
              <option key={tournament.id} value={tournament.id}>
                {tournament.name}
              </option>
            ))}
          </select>
          <input
            className="team-input"
            type="text"
            placeholder="Team Name"
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
          />
          <button className="submit-button" onClick={handleCreateTeam}>
            Submit
          </button>
        </div>
      )}

      {isJoiningTeam && (
        <div className="join-team-form">
          <h3>Join a Team</h3>
          <input
            type="text"
            placeholder="Team Code"
            value={teamCode}
            onChange={(e) => setTeamCode(e.target.value)}
            className="team-input"
          />
          <button className="submit-button" onClick={handleJoinTeam}>
            Submit
          </button>
        </div>
      )}

      {isJoiningWaitlist && (
        <div className="join-waitlist-form">
          <h3>Join Tournament Waitlist</h3>
          <select
            className="team-select"
            value={selectedTournamentWaitlist}
            onChange={(e) => setSelectedTournamentWaitlist(e.target.value)}
          >
            <option value="" disabled>
              Select Tournament
            </option>
            {tournaments.map((tournament) => (
              <option key={tournament.id} value={tournament.name}>
                {tournament.name}
              </option>
            ))}
          </select>
          <button className="submit-button" onClick={handleJoinWaitlist}>
            Submit
          </button>
        </div>
      )}

      {isEditingWaitlist && (
        <div className = 'join-waitlist-form'>
          <h3>Edit Tournament Waitlist</h3>
          <select
            className="team-select"
            value={editedTournamentWaitlist}
            onChange={(e) => setEditedTournamentWaitlist(e.target.value)}
          >
          <option value="" disabled>
            Select Tournament
          </option>
          {tournaments.map((tournament) => (
            <option key={tournament.id} value={tournament.name}>
              {tournament.name}
            </option>
          ))}
        </select>
        <button className='submit-button' onClick={handleEditWaitlist}>
          Submit
        </button>
      </div>
      )}

      {showModal && <Modal message={modalMessage} onClose={closeModal} />}

      {confirmDeleteModal && (
        <Modal
          message="Are you sure you want to remove your team from Araknid Cup?"
          onClose={() => setConfirmDeleteModal(false)}
          onConfirm={handleDeleteTeam}
        />
      )}

      {confirmLeaveTeamModal && (
        <Modal
          message="Are you sure you want to leave your team?"
          onClose={() => setConfirmLeaveTeamModal(false)}
          onConfirm={handleConfirmLeaveTeam}
        />
      )}

      {confirmLeaveWaitlistModal && (
        <Modal
          message="Are you sure you want to leave the waitlist?"
          onClose={() => setConfirmLeaveWaitlistModal(false)}
          onConfirm={handleConfirmLeaveWaitlist}
        />
      )}
    </div>
  );
};

export default Teams;
