import React from 'react';

const BasicButton = ({ title, disabled, onClick }) => {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            style={{
                padding: '10px 20px',
                backgroundColor: disabled ? '#ccc' : '#2F1C75',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}
        >
            {title}
        </button>
    );
};

export default BasicButton;
